import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { CommentComponent } from './comment/comment.component';
import { CommentListComponent } from './comment-list/comment-list.component';



@NgModule({
  declarations: [
    HeaderComponent, 
    CommentComponent, 
    CommentListComponent
  ],
  imports: [
    CommonModule
  ], 
  exports: [
    HeaderComponent, 
    CommentComponent,
    CommentListComponent
  ]
})
export class SharedModule { }
