
<section class="container" style="margin-top:5rem;">
    <div class="row text-center">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="../../assets/base/logo-color.png">
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div style="padding-top:4rem;">
          <img style="max-height:200px;width:auto;" src="../../assets/base/blog-header.png">
        </div>
      </div>
    </div>
  
    <div class="container container-wide mt-4">
      <div class="blog-menu">
        <div class="row text-center">
          <div class="col-lg-3 col-md-3 col-sm-6 menu-text">
            Featured
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 menu-text">
            Recent
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 menu-text">
            Holiday Notes
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 menu-text">
            Recipe Reviews
          </div>
        </div>
      </div>
    </div>
  </section>
  