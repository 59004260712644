
<!-- Featured Section -->
<div class="container container-wide mt-4 ">
  <header>
    <img  style="width:100%" src="../../assets/recipes/ravioli2.png">
    <div class="header-text">
      <h1>The Best Italian Recipes</h1>
      <h2><i>from the best Italian you know!</i></h2>
    </div>
  </header>
</div>


<!-- Card Section -->
<section class="container container-wide mt-4">
    <div class="row" >
        <div class="col-lg-3 col-md-3 col-sm-12 mt-2 mb-2">
            <div class="card" >
              <a href="/" class="mx-auto">
                <img src="../../assets/icons/soup.png" class="card-img-top home-icon-img" alt="...">
              </a>
                <div class="card-body text-center">
                  <h5 class="card-title">
                    <a href="/">Soups</a>
                  </h5>
                  <p class="card-text">Soup is a staple of any good itilian diet, and these recipes prove it - simple, hearty, and delicious.</p>
                </div>
              </div>
              
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 mt-2 mb-2">
          <div class="card" >
            <a href="/" class="mx-auto">
              <img src="../../assets/icons/ravioli.png" class="card-img-top home-icon-img" alt="...">
            </a>
              <div class="card-body text-center">
                <h5 class="card-title">
                  <a href="/">Pasta</a>
                </h5>
                <p class="card-text">From the coveted holiday plate to Sunday dinner, these  recipes have been favorites for generations.</p>
              </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 mt-2 mb-2">
          <div class="card" >
            <a href="/" class="mx-auto">
              <img src="../../assets/icons/meat.png" class="card-img-top home-icon-img" alt="...">
            </a>
              <div class="card-body text-center">
                <h5 class="card-title">
                  <a href="/">Meat</a>
                </h5>
                <p class="card-text">In Italian cooking, you make the most of whatever meat you have - and these recipes do just that.</p>
              </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 mt-2 mb-2">
          <div class="card" >
            <a href="/" class="mx-auto">
              <img src="../../assets/icons/fish.png" class="card-img-top home-icon-img" alt="...">
            </a>
              <div class="card-body text-center">
                <h5 class="card-title">
                  <a href="/">Fish</a>
                </h5>
                <p class="card-text">Fish makes for an exceptional dish any time of year, but especially for the seven fishes of Chrishmas.</p>
              </div>
          </div>
        </div>
    </div>
</section>

<!-- specials section -->

<section class="container mt-4 mb-4">
  <div class="row" style="vertical-align: middle;">
    <div class="col-lg-6 col-md-6 col-sm-12 bg-purple" style="padding-right:0;">
      <div class="featured-text">
        <div class="header">
          RECIPE OF THE SEASON:
          Homemade Raviolis
        </div>
        <hr style="background-color:#ffffff;">
        <div class="body">
          Stuff goes here.
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12" style="padding-left:0">
        <img class="featured-image" src="../../assets/recipes/homemade-ravioli.JPG">
    </div>
  </div>
</section>

